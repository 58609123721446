@import (reference) '../../base/common.css.less';

// User creation fake form found at (in cpanel)
// app.pixlee.com/app#settings/team

#create_user_lightbox {

    #lightbox_content {

        .access_panel {

            .lightbox_content_wrapper {

                #email_input_section {

                    #password_policy_hint {
                        top: -6px;
                        left: 58px;
                    }
                }
            }
        }
    }
}

// Own account password change found at (in cpanel)
// app.pixlee.com/app#settings/account_settings

#password_change_form {

    #password_policy_hint {
        top: -21px;
        left: 80px;
    }
}

// New account registration form
// pixlee.com/register/form

#sign_up_container {

    #password_policy_hint {
        top: 0;
        left: 115px;

        &::after {
            width: 224px;
        }
    }
}

// Password reset form (from password reset email)
// pixlee.com/reset/<ONETIMEUSEHASH>

#reset_form {

    .edit_user {

        .input_holder {

            #user_password {

                &:focus ~ .label #password_policy_hint {
                    left: 90px;
                }//&:focus ~ .label #password_policy_hint
            }

            #password_policy_hint {
                top: -2px;
                left: 105px;

                &::after {
                    width: 173px;
                }
            }
        }
    }
}
