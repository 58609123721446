@import url('reset.less');
@import url('_old_animations.less');

// overwrite the assets font path
@import '@pixleeturnto/fontawesome-pro/less/_variables.less';
// TODO @matt: can we replace this and just use the direct links?
@fa-font-path: "/node_modules/@pixleeturnto/fontawesome-pro/webfonts";

@import '@pixleeturnto/fontawesome-pro/less/fontawesome.less';
@import '@pixleeturnto/fontawesome-pro/less/regular.less';
@import '@pixleeturnto/fontawesome-pro/less/light.less';
@import '@pixleeturnto/fontawesome-pro/less/solid.less';
@import '@pixleeturnto/fontawesome-pro/less/brands.less';

// NOTE: DEPRECATED - move to using main.less instead

// ╔═╗╦ ╦╔═╗╔╦╗╔═╗╦ ╦╔═╗╔═╗
// ╚═╗║║║╠═╣ ║ ║  ╠═╣║╣ ╚═╗
// ╚═╝╚╩╝╩ ╩ ╩ ╚═╝╩ ╩╚═╝╚═╝

// *_shade colours are 12% darker than their base colour
@blue: #00a8ff;
@aqua: #58d3e5;
@teal: #1fbba6;
@green: #39ca74;
@lime: #b0cd1f;
@lite_yellow: #ffd00c;
@yellow: #f9ac17;
@orange: #ff6d26;
@tomato: #ff6347;
@red: #ff3000;
@pink: #f979a5;
@purple: #9d8dff;
@purple2: #81429f;

@slate0a: #f8f9fd;
@slate0b: #eaedf2;
@slate1: #ebedf3;
@slate1a: #e0e7ef;
@slate1c: #d5dbe3;
@slate1b: #aeb1c0;
@slate2: #8b95a2;
@slate3: #667282;
@slate3a: #505a78;
@slate4: #4d5866;
@slate5: #323a45;
@slate6: #282d39;
@slate7: #2b2f3e;

@impressions: #737c93;
@photos: @lime;
@views: @purple;
@submitters: @aqua;
@shares: @pink;

@error_bg: #fffde2;
@error_color: #ff5300;
@code: #585858;


// ╔╦╗╦═╗ ╦╦╔╗╔╔═╗
// ║║║║╔╩╦╝║║║║╚═╗
// ╩ ╩╩╩ ╚═╩╝╚╝╚═╝


.boxshadow (@boxshadow) {
    box-shadow: @boxshadow;
}


.boxshadow(@boxshadow, @boxshadow1) {
    box-shadow: @boxshadow, @boxshadow1;
}


.boxshadow(@boxshadow, @boxshadow1, @boxshadow2) {
    box-shadow: @boxshadow, @boxshadow1, @boxshadow2;
}


.boxshadow(@boxshadow, @boxshadow1, @boxshadow2, @boxshadow3) {
    box-shadow: @boxshadow, @boxshadow1, @boxshadow2, @boxshadow3;
}

.placeholder(@rules) {
    &::-webkit-input-placeholder {
        @rules();
    }

    &::-moz-input-placeholder {
        @rules();
    }

    :-moz-input-placeholder {
        opacity: 1; //Firefox doesnt display colors the same for placeholders
        @rules();
    }

    &::-ms-input-placeholder {
        @rules();
    }

    &::-o-input-placeholder {
        @rules();
    }
}
//.placeholder

.borderradius (@radius: 0) {
    border-radius: @radius;
}


.transform(@transform) {
    transform: @transform;
}

// TODO: two calls for addClass('fixed') in location_view.js
.fixed {
    position: fixed;
}


// TODO: hard to tell if these are being used, may be added by JS
.left {
    float: left;
}

.right {
    float: right;
}

// TODO: why??????? this is manually used in classes so gotta clear those up first
.pnl {
    background: white;
}


.clear {
    &:after {
        content: '';
        display: block;
        position: relative;
        clear: both;
    }
}

.circle {
    background-color: white;
    border: 2px solid @slate3;
    border-radius: 50%;
    box-sizing: border-box;
    color: @slate3;
    flex-shrink: 0;
    font-family: sofia_bold;
    font-size: 18px;
    height: 30px;
    padding: 5px 0 7px 0;
    text-align: center;
    width: 30px;
}

.instagram_gradient {
    background: radial-gradient(circle farthest-corner at 35% 220%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

// ╦═╗╔═╗╔╦╗╦╔╗╔╔═╗
// ╠╦╝║╣  ║ ║║║║╠═╣
// ╩╚═╚═╝ ╩ ╩╝╚╝╩ ╩

@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

.at2x(@path, @w: auto, @h: auto) {
    background-image: url("@{path}");

    @at2x_path: replace(@path, "\.(\w+)$", "@2x.$1");

    @media @highdpi {
        background-image: url("@{at2x_path}");
        background-size: @w @h;
    }
    // @media @highdpi
}



// ╔═╗╔═╗╔═╗╔═╗
// ╠═╝╠═╣║ ╦║╣
// ╩  ╩ ╩╚═╝╚═╝

// TODO: ???? what is this variable for
@page {
    size: landscape;
    margin: 2cm;
}

// ╦  ╦╔═╗╦ ╦╔╦╗╔╗ ╔═╗═╗ ╦  ╔═╗╦  ╦╔═╗╦═╗╦  ╔═╗╦ ╦
// ║  ║║ ╦╠═╣ ║ ╠╩╗║ ║╔╩╦╝  ║ ║╚╗╔╝║╣ ╠╦╝║  ╠═╣╚╦╝
// ╩═╝╩╚═╝╩ ╩ ╩ ╚═╝╚═╝╩ ╚═  ╚═╝ ╚╝ ╚═╝╩╚═╩═╝╩ ╩ ╩

// TODO: only used in dialoag_region
.lightbox_blur {
    filter: blur(20px) saturate(180%) !important;
    -o-filter: blur(20px) saturate(180%) !important;
    -ms-filter: blur(20px) saturate(180%) !important;
    -moz-filter: blur(20px) saturate(180%) !important;
    -webkit-filter: blur(20px) saturate(180%) !important;
}

// THIS lightbox_blur is for blurring the lighbox


// ╔╦╗╔═╗╔═╗╔═╗╦  ╔═╗  ╔╗ ╦ ╦╔╦╗╔╦╗╔═╗╔╗╔
//  ║ ║ ║║ ╦║ ╦║  ║╣   ╠╩╗║ ║ ║  ║ ║ ║║║║
//  ╩ ╚═╝╚═╝╚═╝╩═╝╚═╝  ╚═╝╚═╝ ╩  ╩ ╚═╝╝╚╝

.switch {
    color: #6f839a !important;
    cursor: pointer;
    .p5;
    line-height: 1.5em;
    border-radius: 3px;
    padding: .125em .5em;
    display: inline-block;
    background-color: #c8ced9;
    -webkit-transition: .15s ease;
    -moz-transition: .15s ease;
    -ms-transition: .15s ease;
    -o-transition: .15s ease;
    transition: .15s ease;
}

#cp_progress {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(40, 45, 57, 0.8);
    opacity: 0.5;
    z-index: var(--z-index-global-progress);
    top: 0px;
    -webkit-animation-duration: 0.25s;
    animation-duration: 0.25s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    .fadeIn;
    left: 0;

    #dot_holder {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .bull {
            display: inline-block;
            margin-right: 5px;
            font-size: 40px;
            color: white;
            -webkit-animation-duration: 1500ms;
            animation-duration: 1500ms;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            .pulse;
            .infinite;

            &:nth-of-type(2) {
                -webkit-animation-delay: 250ms;
                animation-delay: 250ms;
            }

            &:nth-of-type(3) {
                -webkit-animation-delay: 500ms;
                animation-delay: 500ms;
            }
        }
    }

    .error_message {
        position: absolute;
        .fadeIn;
        display: none;
        left: 30%;
        top: 55%;
        font-size: 25px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}



// ╔╦╗╦╔═╗╔═╗╔═╗╦  ╦  ╔═╗╔╗╔╔═╗╔═╗╦ ╦╔═╗
// ║║║║╚═╗║  ║╣ ║  ║  ╠═╣║║║║╣ ║ ║║ ║╚═╗
// ╩ ╩╩╚═╝╚═╝╚═╝╩═╝╩═╝╩ ╩╝╚╝╚═╝╚═╝╚═╝╚═╝



// TODO: probably deprecated
#powered_by_stripe {
    width: 124px;
    height: 21px;
    background: url('~embed/common/stripe.png') center center no-repeat transparent;
}

// TODO: this is supposed to be title
.ttl {
    display: block;
    height: 27px;
    text-transform: uppercase;
    padding: 7px 10px 0px 10px;
    font-weight: bold;
    color: white;

    a {
        color: white;
        font-weight: normal;
        float: right;
        font-size: 14px;
    }

    a:hover {
        border-bottom: 1px dotted white;
    }

    span {
        color: white;
        font-weight: normal;
        float: right;
        font-size: 14px;
    }
}

.permission_alert_msg {
    color: var(--blox-slate3);

    span {
        font-weight: 700;
        color: var(--blox-slate5);

        &.permission_date,
        &.permission_status_text {
            color: var(--blox-red);
        }
    }
}
.ptt_content_rights_disclaimer {
    color: var(--blox-slate3);
    margin-top: 7px;
    font-family: var(--blox-font-family);
    font-style: italic;
    font-size: var(--blox-font-size-sm);
}
