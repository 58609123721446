// Mixins related to animation

////////////////// transition animations //////////////////

.transition_animation() {
    transition: all 0.7s ease;
} // transition

.fast_transition_animation() {
    transition: all 0.25s ease;
}

////////////////// jelly_initial_animation //////////////////

.jelly_initial_animation() {
    animation: jelly_initial 1000ms linear both;
}

.jelly_animation() {
    animation: jelly 1000ms linear both;
}

////////////////// jelly //////////////////

@keyframes jelly {
    .jelly_frame();
}

.jelly_frame() {
    0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    3.333333% { transform: matrix3d(1.04736, 0, 0, 0, 0, 1.06844, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.666667% { transform: matrix3d(1.06716, 0, 0, 0, 0, 1.08595, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10% { transform: matrix3d(1.06672, 0, 0, 0, 0, 1.06672, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    13.333333% { transform: matrix3d(1.05415, 0, 0, 0, 0, 1.03201, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    16.666667% { transform: matrix3d(1.0366, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    20% { transform: matrix3d(1.01928, 0, 0, 0, 0, 0.98072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    23.333333% { transform: matrix3d(1.00529, 0, 0, 0, 0, 0.97578, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    26.666667% { transform: matrix3d(0.99589, 0, 0, 0, 0, 0.9812, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    30% { transform: matrix3d(0.99098, 0, 0, 0, 0, 0.99098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    33.333333% { transform: matrix3d(0.98969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.666667% { transform: matrix3d(0.99081, 0, 0, 0, 0, 1.00543, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    40% { transform: matrix3d(0.99318, 0, 0, 0, 0, 1.00682, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    43.333333% { transform: matrix3d(0.99586, 0, 0, 0, 0, 1.0053, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    46.666667% { transform: matrix3d(0.99824, 0, 0, 0, 0, 1.00254, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    50% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    53.333333% { transform: matrix3d(1.00106, 0, 0, 0, 0, 0.99847, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    56.666667% { transform: matrix3d(1.0015, 0, 0, 0, 0, 0.99808, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    60% { transform: matrix3d(1.00149, 0, 0, 0, 0, 0.99851, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    63.333333% { transform: matrix3d(1.00121, 0, 0, 0, 0, 0.99928, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    66.666667% { transform: matrix3d(1.00082, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    70% { transform: matrix3d(1.00043, 0, 0, 0, 0, 1.00043, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    73.333333% { transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00054, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    76.666667% { transform: matrix3d(0.99991, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    80% { transform: matrix3d(0.9998, 0, 0, 0, 0, 1.0002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    83.333333% { transform: matrix3d(0.99977, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    86.666667% { transform: matrix3d(0.99979, 0, 0, 0, 0, 0.99988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90% { transform: matrix3d(0.99985, 0, 0, 0, 0, 0.99985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    93.333333% { transform: matrix3d(0.99991, 0, 0, 0, 0, 0.99988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    96.666667% { transform: matrix3d(0.99996, 0, 0, 0, 0, 0.99994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}


////////////////// jelly_initial //////////////////

@keyframes jelly_initial {
    .jelly_initial_frame();
}

.jelly_initial_frame() {
    0% { opacity: 0; transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    2.083333% { transform: matrix3d(0.75266, 0, 0, 0, 0, 0.76342, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    4.166667% { transform: matrix3d(0.81071, 0, 0, 0, 0, 0.84545, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.25% { transform: matrix3d(0.86808, 0, 0, 0, 0, 0.9286, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    8.333333% { transform: matrix3d(0.92038, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10.416667% { transform: matrix3d(0.96482, 0, 0, 0, 0, 1.05202, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    12.5% { transform: matrix3d(1, 0, 0, 0, 0, 1.08204, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    14.583333% { transform: matrix3d(1.02563, 0, 0, 0, 0, 1.09149, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    16.666667% { transform: matrix3d(1.04227, 0, 0, 0, 0, 1.08453, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    18.75% { transform: matrix3d(1.05102, 0, 0, 0, 0, 1.06666, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    20.833333% { transform: matrix3d(1.05334, 0, 0, 0, 0, 1.04355, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    22.916667% { transform: matrix3d(1.05078, 0, 0, 0, 0, 1.02012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25% { transform: matrix3d(1.04487, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    27.083333% { transform: matrix3d(1.03699, 0, 0, 0, 0, 0.98534, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.166667% { transform: matrix3d(1.02831, 0, 0, 0, 0, 0.97688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    31.25% { transform: matrix3d(1.01973, 0, 0, 0, 0, 0.97422, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    33.333333% { transform: matrix3d(1.01191, 0, 0, 0, 0, 0.97618, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.416667% { transform: matrix3d(1.00526, 0, 0, 0, 0, 0.98122, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    37.5% { transform: matrix3d(1, 0, 0, 0, 0, 0.98773, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    39.583333% { transform: matrix3d(0.99617, 0, 0, 0, 0, 0.99433, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    41.666667% { transform: matrix3d(0.99368, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    43.75% { transform: matrix3d(0.99237, 0, 0, 0, 0, 1.00413, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    45.833333% { transform: matrix3d(0.99202, 0, 0, 0, 0, 1.00651, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    47.916667% { transform: matrix3d(0.99241, 0, 0, 0, 0, 1.00726, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    50% { opacity: 1; transform: matrix3d(0.99329, 0, 0, 0, 0, 1.00671, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.083333% { transform: matrix3d(0.99447, 0, 0, 0, 0, 1.00529, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    54.166667% { transform: matrix3d(0.99577, 0, 0, 0, 0, 1.00346, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    56.25% { transform: matrix3d(0.99705, 0, 0, 0, 0, 1.0016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    58.333333% { transform: matrix3d(0.99822, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    60.416667% { transform: matrix3d(0.99921, 0, 0, 0, 0, 0.99884, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    62.5% { transform: matrix3d(1, 0, 0, 0, 0, 0.99816, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    64.583333% { transform: matrix3d(1.00057, 0, 0, 0, 0, 0.99795, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    66.666667% { transform: matrix3d(1.00095, 0, 0, 0, 0, 0.99811, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    68.75% { transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99851, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    70.833333% { transform: matrix3d(1.00119, 0, 0, 0, 0, 0.99903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    72.916667% { transform: matrix3d(1.00114, 0, 0, 0, 0, 0.99955, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75% { transform: matrix3d(1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    77.083333% { transform: matrix3d(1.00083, 0, 0, 0, 0, 1.00033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    79.166667% { transform: matrix3d(1.00063, 0, 0, 0, 0, 1.00052, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    81.25% { transform: matrix3d(1.00044, 0, 0, 0, 0, 1.00058, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    83.333333% { transform: matrix3d(1.00027, 0, 0, 0, 0, 1.00053, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.416667% { transform: matrix3d(1.00012, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    87.5% { transform: matrix3d(1, 0, 0, 0, 0, 1.00027, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    89.583333% { transform: matrix3d(0.99991, 0, 0, 0, 0, 1.00013, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    91.666667% { transform: matrix3d(0.99986, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    93.75% { transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    95.833333% { transform: matrix3d(0.99982, 0, 0, 0, 0, 0.99985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    97.916667% { transform: matrix3d(0.99983, 0, 0, 0, 0, 0.99984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { opacity: 1; transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
}

////////////////// zoom_out_right //////////////////

.zoom_out_right() {
    animation: zoom_out_right;
}

@keyframes zoom_out_right {

    40% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    }

    100% {
        opacity: 0;
        transform: scale(0.1) translate3d(2000px, 0, 0);
        transform-origin: right center;
    }
}

////////////////// bounce_in_down //////////////////

.bounce_in_down() {
    animation-name: bounce_in_down;
}

@keyframes bounce_in_down {

    0%,
    60%,
    75%,
    90%,
    100% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, 25px, 0);
    }

    75% {
        transform: translate3d(0, -10px, 0);
    }

    90% {
        transform: translate3d(0, 5px, 0);
    }

    100% {
        transform: none;
    }
}

////////////////// animated //////////////////

.animated() {
    animation-duration: 1s;
    animation-fill-mode: both;
}

////////////////// bounce_on_click //////////////////
// animation will cause element to bounce on click
.bounce_on_click() {
    animation: bounce_on_click ease-out 600ms;
}
    
@keyframes bounce_on_click {
    0% {
        transform: scale(1);
      }

      20% {
        transform: scale(1.05);
      }

      40% {
        transform: scale(0.95);
      }

      60% {
        transform: scale(1.025);
      }

      80% {
        transform: scale(0.975);
      }

      100% {
        transform: scale(1);
      }
  }
