@import (reference) '../../base/common.css.less';
@import (reference) '../../base/main.less';

a:hover,
a:visited,
a:active {
    color: @blue;
}

#login_title {
    position: fixed;
    top: 15px;
    left: 15px;
    margin: 0 auto;
    .sm;

    font-size: 11px;
    text-transform: uppercase;
    .fast_transition_animation();

    &:hover {
        color: @slate5;
    }//&:hover
}//login_title

#reset_title {
    .sm;

    font-size: 14px;
    text-transform: uppercase;
    color: @slate4;
    text-align: left;
    padding: 20px 40px;
}

#privacy_policy_hint {
    .at2x('~embed/glyph/hint.png', 17px, 17px);

    width: 17px;
    height: 17px;
    position: absolute;
    .fast_transition_animation();

    top: 2px;
    left: 225px;
    z-index: 99 !important;

    &::after {
        content: "Allow Pixlee to track certain actions you take in the platofrm to help us imporve our platform through analytics & debugging tools";
        background: white;
        color: @slate3;
        .sl;

        font-size: 12px;
        line-height: 14px;
        text-align: left;
        text-transform: none;
        text-overflow: initial;
        .drop_shadow();

        white-space: pre-line;
        overflow: auto;
        position: absolute;
        width: 150px;
        border-radius: 3px;
        padding: 7px 10px;
        visibility: hidden;
        opacity: 0;
        .fast_transition_animation();

        top: 0;
        left: -60px;
    } // &:after

    &:hover {

        &::after {
            z-index: 99 !important;
            opacity: 1;
            visibility: visible;
        }//&:after
    }//&:hover
}//#privacy_policy_hint

#privacy_policy_hint i::after {
    left: 40px;
}//#privacy_policy_hint i:after

#login_wrapper,
#reset_wrapper {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    .transform(translate(-50%, -50%));

    z-index: 1;

    #login_holder,
    #reset_form,
    #reset_holder {
        width: 380px;
        padding-top: 40px;
        display: block;
        text-align: center;
        margin: 0 auto;
        color: @slate4;
        background-color: white;
        .boxshadow(0 2px 32px rgba(0, 0, 0, 0.12));

        border-radius: 3px;
        overflow: hidden;

        #pixlee_logo {
            text-align: left;
            padding: 0 40px 20px;

            h1 {
                padding-left: 23px;
                .at2x('~embed/glyph/logo.png', 20px, 20px);

                background-repeat: no-repeat;
                background-position: left top 2px;
                display: block;
                .sel;

                text-transform: uppercase;
                color: @slate4;
                font-size: 29px;
            }

            h2 {
                display: block;
                .sr;

                font-size: 13px;
                padding-top: 5px;
                color: @slate2;
                text-transform: capitalize;
            }
        }//#pixlee_logo

        .input_holder {
            display: block;
            text-align: left; // For some reason text-align: left only works here and not in label.error
            position: relative;
            margin: 30px 40px;

            /* z-index: 12; */

            .label {
                position: absolute;
                top: 18px;
                left: 0;
                .sm;

                text-transform: capitalize;
                font-size: 14px;
                .fast_transition_animation();

                &.checkbox_label {
                    left: 20px;
                    top: 2px;
                }
            }//.label

            label.error {
                color: @red;
                margin-top: -12px;
                margin-bottom: 10px;
                font-size: 12px;
                .book;
            } // error

            label.error::before {
                content: "* ";
            } // error:before

            #privacy_checkbox {
                opacity: 0;
                cursor: pointer;
            }

            #privacy_checkbox + span {
                width: 13px;
                height: 13px;
                background-color: @slate0a;
                .boxshadow(0 0 0 1px @slate1);

                border-radius: 3px;
                overflow: hidden;
                margin-left: 0;
                margin-top: 4px;
                position: absolute;
                cursor: pointer;
                .fast_transition_animation();
            } //#privacy_checkbox

            #privacy_checkbox:checked + span {
                width: 13px;
                height: 13px;
                background-color: @slate0a;
                .boxshadow(0 0 0 1px @slate1);

                border-radius: 3px;
                overflow: hidden;
                margin-left: 0;
                margin-top: 4px;
                position: absolute;
                cursor: pointer;

                &::before {
                    font-family: icons;
                    content: '\f00c';
                    font-size: 11px;
                    position: relative;
                    top: -4px;
                    color: @slate2;
                }//&:before
            }//#privacy_checkbox
        }//.input_holder


        #user_session_email,
        #user_session_password,
        #email,
        #user_password,
        #user_password_confirmation {
            display: block;
            font-size: 14px;
            color: @slate3;
            .fast_transition_animation();
            .sm;

            opacity: 1;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            .boxshadow(1000px 0 0 0 white inset, 0 1px 0 0 @slate1);

            &:hover,
            &:focus {
                .boxshadow(1000px 0 0 0 white inset, 0 1px 0 0 @blue);
            } // &:hover, &:focus

            &:focus {
                width: calc(~'100% - 20px');
                padding: 18px 20px 18px 0;
            }//&:focus

            &:focus ~ .label {
                top: -10px;
                font-size: 12px;
                color: @slate3;
            }//&:focus ~ .label
        }//#user_session_password, #email, #user_password, #user_password_confirmation

        #user_session_email {
            width: calc(~'100% - 70px');
            padding: 18px 20px 18px 50px;
        }//#user_session_email

        #user_session_password {
            width: calc(~'100% - 100px');
            padding: 18px 20px 18px 80px;
        }//#user_session_password

        #email {
            width: calc(~'100% - 160px');
            padding: 18px 20px 18px 140px;
        }//#email

        #user_password {
            width: calc(~'100% - 130px');
            padding: 18px 20px 18px 130px;
        }//#user_password

        #user_password_confirmation {
            width: calc(~'100% - 180px');
            padding: 18px 20px 18px 160px;
        }//#user_password_confirmation

        .bottom_inputs {
            display: block;
            margin: 0 40px;
            padding: 40px 0;

            &::after {
                clear: both;
                content: '';
                display: block;
            }//&:after

            #login_label {
                float: left;

                p {
                    display: inline-block;
                    font-size: 12px;
                    color: @slate2;
                    font-weight: 500;
                    cursor: pointer;
                    margin-left: 20px;
                    .sr;
                }//p

                #login_rememberme {
                    opacity: 0;
                    cursor: pointer;
                }

                #login_rememberme + span {
                    width: 13px;
                    height: 13px;
                    background-color: @slate0a;
                    .boxshadow(0 0 0 1px @slate1);

                    border-radius: 3px;
                    overflow: hidden;
                    position: absolute;
                    margin-left: 0;
                    margin-top: 4px;
                    pointer-events: none;
                    cursor: pointer;
                    .fast_transition_animation();
                }

                #login_rememberme:checked + span {
                    width: 13px;
                    height: 13px;
                    background-color: @slate0a;
                    .boxshadow(0 0 0 1px @slate1);

                    border-radius: 3px;
                    overflow: hidden;
                    position: absolute;
                    margin-left: 0;
                    margin-top: 4px;
                    pointer-events: none;
                    cursor: pointer;

                    &::before {
                        font-family: icons;
                        content: '\f00c';
                        font-size: 11px;
                        position: relative;
                        top: -4px;
                        color: @slate2;
                    }//&:before
                }
            }//login_label

            #login_forgot {
                font-size: 12px;
                float: right;
                padding-top: 5px;

                a {
                    color: @blue;
                    .sm;
                    .fast_transition_animation();
                } // a

                a:hover {
                    color: @blue - #222;
                } // a:hover
            }//login_forgot
        }

        #login_submit,
        input[type='submit'],
        #user_submit {
            text-transform: uppercase;
            color: white;
            width: 100%;
            background-color: @green;
            display: block;
            .sb;

            font-size: 14px;
            padding: 30px 0 28px;
            .fast_transition_animation();

            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;

            &:hover {
                background-color: @green - #111;
            } // &:hover
        }// login_submit, reset_submit input[type='submit'], user_submit
    }//login_form

    #instagram_analytics,
    #demo_request,
    #privacy_policy {
        width: 100%;

        p {
            font-size: 13px;
            .sm;

            text-align: center;
        }//p

        a {
            .sr;

            padding-top: 8px;
            text-align: center;
            font-size: 13px;
            .fast_transition_animation();

            &:hover {
                color: @blue - #222;
            }
        }//a
    }//#instagram_analytics

    #instagram_analytics {
        padding-top: 80px;
    }//#instagram_analytics

    #demo_request {
        padding-top: 30px;

        a {
            display: block;
        }//a
    }//#demo_request

    #privacy_policy {
        padding-top: 25px;

        a {
            display: block;
        }//a
    }//#privacy_policy
}//login_wrapper

@media (max-width: 414px) {
}

#reset_wrapper {
    height: 180px;
    margin-top: -90px;
}

.login_error_display,
.login_notice_display,
.reset_error_display,
.reset_notice_display {
    display: block;
    font-size: 12px;
    padding: 0 40px;
    text-align: left;

    .sm;
}

.login_error_display,
.reset_error_display {
    color: @tomato;
}

.login_notice_display,
.reset_notice_display {
    color: @blue;
}

.back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: @slate0a;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background: #f8f9fd;
    background: -moz-radial-gradient(center, circle cover, #f8f9fd 52%, #e0e7ef 100%);
    background: -webkit-radial-gradient(center, circle cover, #f8f9fd 52%, #e0e7ef 100%);
    background: radial-gradient(circle at center, #f8f9fd 52%, #e0e7ef 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f8f9fd', endColorstr='#e0e7ef', GradientType=1);
}//back

/***************** to easily change the text colors when adding bg images */

.gray_text {
    color: @slate2 !important;
}

.blue_text {
    color: @blue !important;
}

.hbl_pal_title_bg {
    background-color: @blue !important;
}
