@font-face {
    font-family: p1;
    src: url(https://assets.pixlee.com/pixlee_fonts/pixlee_100.otf);
}

@font-face {
    font-family: p3;
    src: url(https://assets.pixlee.com/pixlee_fonts/pixlee_300.otf);
}

@font-face {
    font-family: p5;
    src: url(https://assets.pixlee.com/pixlee_fonts/pixlee_500.otf);
}

@font-face {
    font-family: p7;
    src: url(https://assets.pixlee.com/pixlee_fonts/pixlee_700.otf);
}


/*
  Deprecated Pixlee Icons
*/
@font-face {
    font-family: icons;
    src: url(https://assets.pixlee.com/pixlee_fonts/pixlee_icons.otf);
}


/*
  Pixlee Icons that are used in CP
*/
@font-face {
    font-family: pixlee_icons;
    src: url(https://assets.pixlee.com/pixlee_fonts/pixlee_cp_icons/pixlee_cp_icons_v2.ttf);
}


@font-face {
    font-family: book;
    src: url(https://assets.pixlee.com/pixlee_fonts/Futura_PT_book.otf);
}

@font-face {
    font-family: heavy;
    src: url(https://assets.pixlee.com/pixlee_fonts/Futura_PT_heavy.otf);
}



/* TODO: why do we create different font families, instead of just setting one with different weights? */
@font-face {
    font-family: sbl;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_black.otf);
}

@font-face {
    font-family: sb;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_bold.otf);
}

@font-face {
    font-family: ssb;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_semi_bold.otf);
}

@font-face {
    font-family: sm;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_medium.otf);
}

@font-face {
    font-family: sr;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_regular.otf);
}

@font-face {
    font-family: sl;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_light.otf);
}

@font-face {
    font-family: sel;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_extra_light.otf);
}

@font-face {
    font-family: sul;
    src: url(https://assets.pixlee.com/pixlee_fonts/sofia_ultra_light.otf);
}


.sbl {
    font-family: sbl;
}


.sb {
    font-family: sb;
}


.ssb {
    font-family: ssb;
}


.sm {
    font-family: sm;
}


.sr {
    font-family: sr;
}


.sl {
    font-family: sl;
}


.sel {
    font-family: sel;
}


.sul {
    font-family: sul;
}


.book {
    font-family: book, sans-serif;
}


.heavy {
    font-family: heavy, sans-serif;
}


.p1 {
    font-family: p1, sans-serif;
}


.p3 {
    font-family: p3, sans-serif;
}


.p5 {
    font-family: p5, sans-serif;
}


.p7 {
    font-family: p7, sans-serif;
}


.icons {
    font-family: icons;
}

*,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
tspan,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
    outline: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible !important;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}


body,
html {
    overflow-x: hidden;
}

tspan {
    font-size: 12px;
    font-family: Verdana, sans-serif;
    font-weight: bold;
}

textarea {
    -ms-resize: none !important; /* NB. IE doesn't support this property, but it's under consideration. */
    resize: none !important;
}

a,
input[type='submit'],
button {
    cursor: pointer;
}

input[type='text'],
textarea {
    cursor: text;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
}

::selection {
    background: rgba(0, 168, 255, 1);
    color: white;
}
