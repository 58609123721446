// Mixins

/** -------------------------------------------------------- **/

@highdpi: ~"(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";

.background_image(@path) {
    // @path should start with 'embed/' (e.g. 'embed/glyph/avatar.jpg')
    background-image: url(@path);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @at2x_path: replace(@path, "\.(\w+)$", "@2x.$1");

    @media @highdpi {
        background-image: url("@{at2x_path}");
    }

    // @media @highdpi

}

// .background_image(@path)

.background_image(@path, @size) {
    // @path should start with 'embed/' (e.g. 'embed/glyph/avatar.jpg')
    background-image: url(@path);
    background-size: @size;
    background-position: center;
    background-repeat: no-repeat;

    @at2x_path: replace(@path, "\.(\w+)$", "@2x.$1");

    @media @highdpi {
        background-image: url("@{at2x_path}");
    }

    // @media @highdpi
}

// .background_image(@path, @size)

.background_image(@path,  @size, @position) {
    // @path should start with 'embed/' (e.g. 'embed/glyph/avatar.jpg')
    background-image: url(@path);
    background-size: @size;
    background-position: @position;
    background-repeat: no-repeat;

    @at2x_path: replace(@path, "\.(\w+)$", "@2x.$1");

    @media @highdpi {
        background-image: url("@{at2x_path}");
    }

    // @media @highdpi
}

// .background_image(@path, @size)

.background_svg(@path) {
    background-image: url(@path);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

// .background_svg(@path)

.background_svg(@path, @size) {
    background-image: url(@path);
    background-size: @size;
    background-position: center;
    background-repeat: no-repeat;
}

/* Mixin for purple gradient base color; pass a number to set opacity */
.background_purple_color(@opacity) {
    background: rgba(135, 134, 248, @opacity);
}

// .background_svg(@path, @size)

/** -------------------------------------------------------- **/

.clear() {
    &:after {
        content: '';
        clear: both;
        display: block;
        position: relative;
    }
}

/** -------------------------------------------------------- **/

.drop_shadow() {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 18px #b8c4d1;
}


/** -------------------------------------------------------- **/

.background_cover() {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background_contain() {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

/** -------------------------------------------------------- **/

.ellipsis_properties() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/**
* This is NOT a perfect solution. The clamp property is not available in IE according to caniuse.com
* this solution also requires you to know EXACTLY how many lines you're clamping, so if you're text goes over the number of lines
* you've entered, the ellipsis will show up in the wrong line.
* An alternative solution may be to use a NPM package
* For multiline ellipsis https://stackoverflow.com/a/19049457
*/

.multi_line_ellipsis(@number_of_lines_to_clamp) {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: @number_of_lines_to_clamp;
    -webkit-box-orient: vertical;
}

// ellipsis

/** -------------------------------------------------------- **/

.input_placeholder_color(@color) {
    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: @color;
    }

    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: @color;
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: @color;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: @color;
    }
}

// .input_placeholder_color(@color)

/** -------------------------------------------------------- **/

.appearance_none() {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}

/** -------------------------------------------------------- **/

.loader() {
    .background_image('~embed/glyph/loader.gif', 41px 9px);
}

/**
* Enable the horizontal scrollbar when the regular overflow strategy isnt work
* https://medium.com/@tommybernaciak/css-horizontal-scroll-25625e65af9
*/
.horizontal_scrollbar() {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    // Apply the sizing for all immediate children elements (1 level)
    &>* {
        flex: 0 0 auto;
    }

}

.fontawesome_icon(@code, @font_size) {
    content: @code;
    font-family: 'Font Awesome 6 Pro';
    font-size: @font_size;
    font-weight: 300;
}

.carousel_icon() {
    position: absolute;
    bottom: 3%;
    left: 3%;
    color: white;
    box-shadow: 0px 0px 8px #0000001A;
    font-size: 14px;

    i {
        font-size: 18px;
    }
}

// TODO: @matt - consider the possibility of running sub files for each "component"'s mixins

// ---------------------- BUTTON MIXINS ----------------------

.button_primary_color(@color) {
    background-color: @color;

    &:hover {
        background: darken(@color, 6);
    }

    &:active {
        background: darken(@color, 12);
    }
}


// Used to customize colors SPECIFICALLY for Secondary Buttons
.button_secondary_color(@color) {
    border: 2px solid @color;
    color: @color;

    &:active,
    &:hover {
        background: @color;
        color: white;
    }

    &:disabled {
        border: 2px solid fade(@color, 40%);
        color: fade(@color, 40%);

        &:hover {
            background: inherit;
        }
    }
}

// Customize colors for Text/Is Flat Button
.button_text_color(@color) {
    color: @color;

    &:hover {
        color: darken(@color, 6);
    }

    &:active {
        color: darken(@color, 12);
    }
}

.center_with_flexbox() {
    display: flex;
    align-items: center;
    justify-content: center;
}

// ---- Layout mixins -----
.left_sidebar() {
    width: 233px;
    background: white;
    .fast_transition_animation();
}

// Fixed section of a left sidebar.
.fixed_section() {
    background: white;
    box-shadow: 0 0 0 1px #dde1e8;
    height: 100%;
    width: 233px;
    position: fixed;
}

// Scrollable section of a left sidebar.
.scrollable_section() {
    display: block;
    position: relative;
    overflow: hidden;
}

// Text description of a lightbox modal shows right under the lightbox header
.modal_description() {
    width: 400px;
    max-width: 400px;
    color: @slate3;
    margin-bottom: 10px;
    font-family: sofia_regular;
    line-height: 22px;
}

.empty_data_section() {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: @dark_gray;
    padding-top: 160px;

    i {
        color: @dark_silver;
    }

    h1 {
        font-family: sofia_regular;
        font-size: 20px;
        line-height: 24px;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    p {
        color: @dark_gray;

        &.empty_text_details {
            font-family: sofia_light;
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 20px;
        }

        a {
            font-size: 16px;
            text-decoration: underline;
        }
    }
}
